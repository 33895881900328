<template>
  <ag-modal-container
    title="Types de relevés supprimés"
    width="900px"
    @close="back"
  >
    <div
      v-if="!loadingData && !recordTypeList.length"
      class="text-center title mt-5"
    >
      Aucun élément
    </div>

    <record-type-list
      v-else
      :value="recordTypeList"
      :selected.sync="selectedRecordTypeList"
      :loading="loadingData"
      light
    />

    <template v-slot:footer>
      <v-spacer />
      <ag-confirm-dialog
        title="Confirmation"
        :message="restoreMessage"
        cancel-label="Annuler"
        confirm-label="Restaurer"
        validation-btn-color="accent"
        @confirm="restoreSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRestore"
            :disabled="!selectedRecordTypeList.length || loadingRestore"
            color="accent"
            class="ml-5"
            v-bind="attrs"
            v-on="on"
            v-text="'Restaurer'"
          />
        </template>
      </ag-confirm-dialog>
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import RecordTypeList from '../components/RecordTypeList'

export default {
  name: 'RecordTypeRestoreContainer',
  components: {
    AgModalContainer,
    AgConfirmDialog,
    RecordTypeList,
  },
  data () {
    return {
      recordTypeList: [],
      selectedRecordTypeList: [],
      loadingData: false,
      loadingRestore: false,
    }
  },
  computed: {
    restoreMessage () {
      const count = this.selectedRecordTypeList.length
      return `Souhaitez-vous restaurer ${count} type(s) de relevé(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'RecordTypeRestore') {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async fetchData () {
      this.loadingData = true
      this.recordTypeList = await Repositories.recordType.getList({ archived: true })
      this.loadingData = false
    },
    async restoreSelected () {
      try {
        this.loadingRestore = true
        const count = this.selectedRecordTypeList.length
        await Repositories.recordType.restore(
          this.selectedRecordTypeList.map(select => select._id),
        )

        this.selectedRecordTypeList = []
        this.fetchData()

        this.showSuccessNotification(`Vous avez restoré ${count} type(s) de relevé(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRestore = false
      }
    },
  },
}
</script>
